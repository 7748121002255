import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import RouteItem from "./RouteItem";
import CardList from "./RouteItem";
import axios from "axios";
import { BASE_URL, headers } from "../../api/Api";

const PathSection = styled.section`
  text-align: center;
  padding: 20px;
`;

const SectionTitle = styled.h2`
  color: var(--3rd, #14183e);
  font: 700 50px Volkhov, sans-serif;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const LearningPaths = () => {
  const [donnees, setDonnees] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}level/three`, headers)
      .then((response) => {
        if (response.data.state === 1) {
          setDonnees(response.data.data);
          console.log(JSON.stringify(response));
        } else {
          setDonnees([]);
        }
      })
      .catch((error) => {
        console.error("Une erreur est survenue :", error);
      });
  }, []); // Passer un tableau vide comme dépendance pour exécuter l'effet une seule fois au chargement du composant

  return (
    <PathSection>
      <SectionTitle>Niveau primaire</SectionTitle>
      <CardList items={donnees} />{" "}
      {/* Utilisation du composant CardList pour afficher les cartes */}
    </PathSection>
  );
};

export default LearningPaths;
