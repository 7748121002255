import React from "react";
import styled from "styled-components";
import { IMAGE_URL } from "../../api/Api";


const Card = styled.div`
  border-radius: 36px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.815px 46.852px rgba(0, 0, 0, 0.02),
    0px 38.519px 25.481px rgba(0, 0, 0, 0.01), 0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.148px 6.519px rgba(0, 0, 0, 0.01),
    0px 1.852px 3.148px rgba(0, 0, 0, 0);
  background-color: var(--white, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 35px 0px;
  width: 200px;
  cursor: pointer;
  transition: transform 0.3s; /* Ajouter une transition pour une animation fluide */

  &:hover {
    transform: scale(1.05); /* Agrandir légèrement la carte au survol */
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 270px;
  }
`;

const CourseImage = styled.img`
  border-radius: 10px;
  width: 190px;
  height: 122px;
  object-fit: contain;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
`;

const CourseTitle = styled.h3`
  color: var(--text-heading-color, #1e1d4c);
  font: 600 20px Poppins, sans-serif;
  margin: 15px 0;
`;

const CourseDescription = styled.p`
  color: var(--TEXT-CLR, #5e6282);
  font: 500 16px Montserrat, sans-serif;
`;

const CourseCard = ({  id, src, title, content_json  }) => (
  <Card>
    <CourseImage src={IMAGE_URL + content_json.images[0]} alt={title} loading="lazy" />
    <CourseTitle>{title}</CourseTitle>
    <CourseDescription>{content_json.description}</CourseDescription>
  </Card>
);

export default CourseCard;
