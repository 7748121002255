import React from "react";
import styled from "styled-components";
import { IMAGE_URL } from "../../api/Api";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1000px; /* Ajustez la largeur pour contenir les cartes */
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 30%; /* Chaque carte occupe 30% de la largeur du conteneur */
  margin: 10px 0; /* Ajustez l'espacement vertical entre les lignes */
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%; /* En mode responsive, chaque carte occupe 100% de la largeur du conteneur */
  }
`;

const CardImage = styled.img`
  width: 100%;
  flex: 1;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const CardFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  padding: 20px 0;
  height: 15%;
  gap: 10px;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  color: white;
`;

const CardButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: #ff4592;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #b61175;
  }
`;

const openRoute = (id) => {
  alert(`the item done is ${id}`);
};

const CardDescription = styled.p`
  color: white;
  font: 500 16px Montserrat, sans-serif;
  margin: 10px;
`;

// Fonction pour générer une couleur aléatoire
const getRandomColor = () => {
  const letters = '01234567';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};

const CardItem = ({ id, title, content_json }) => {
  const randomColor = getRandomColor(); // Générer la couleur aléatoire
  return (
    <Card>
      <CardImage src={IMAGE_URL + content_json.images[0]} alt={title} />
      <CardFooter bgColor={randomColor}>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{content_json.description}</CardDescription>
        <CardButton onClick={() => openRoute(id)}>Suivre</CardButton>
      </CardFooter>
    </Card>
  );
};

const CardList = ({ items }) => (
  <CardContainer>
    {items.map((item) => (
      <CardItem key={item.id} {...item} />
    ))}
  </CardContainer>
);

export default CardList;
