import React from "react";
import styled from "styled-components";
import ProductGrid from "./ProductGrid";
import { TypeAnimation } from "react-type-animation";

const Button = styled.button`
  font-family: Noto Sans JP, sans-serif;
  border-radius: 10px;
  background: #605bff;
  box-shadow: 0 5px 10px 0 color(display-p3 0 0 0 / 0.25);
  color: color(display-p3 1 1 1);
  font-weight: bold;
  border: none;
  padding: 12px 32px;
  cursor: pointer;
`;

const ViewMore = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 50px;
`;

const AnimatedHeading = styled(TypeAnimation)`
  font-family: Poppins, sans-serif;
  font-size: 18px;
  color: color(display-p3 0.0783 0.0806 0.0842);
  font-weight: 600;
  line-height: 111%;
  font-feature-settings: "clig" off, "liga" off;

  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
    white-space: normal;
  }
`;

function Routs() {
  const productImages = [
    "/Assets/sac.png",
    "/Assets/tasse.png",
    "/Assets/coque.png",
  ];

  return (
    <StoreContainer>
      <Header>
        <AnimatedHeading
          sequence={[
            "Parcours ",
            1000,
            "Route ",
            1000,
            "Ukélasi ",
            1000,
            "Kélasi ",
            1000,
          ]}
          speed={50}
          repeat={Infinity}
        />
      </Header>
      <MainContent>
        <ProductGrid images={productImages} />
      </MainContent>
      <ViewMore>
        <Button>Voir plus</Button>
      </ViewMore>
    </StoreContainer>
  );
}

const StoreContainer = styled.main`
  border-radius: 16px;
  /* background: color(display-p3 1 1 1); */
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px;
`;

const MainContent = styled.section`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  padding: 0 0 30px 5px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default Routs;
