import React from "react";
import MainPage from "./screens/MainPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainStore from "./screens/MainStore";
import MainSubscription from "./screens/MainSubscription";
import MainEvent from "./screens/MainEvents";
import MainAloo from "./screens/MainAloo";
import MainLab from "./screens/MainLabs";
import MainRoute from "./screens/MainRoute";
import MainCourse from "./screens/MainCourse";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="store" element={<MainStore />} />
        <Route path="subscription" element={<MainSubscription />} />
        <Route path="event" element={<MainEvent />} />
        <Route path="lab" element={<MainLab />} />
        <Route path="aloo" element={<MainAloo />} />
        <Route path="route" element={<MainRoute />} />
        <Route path="course" element={<MainCourse />} />
      </Routes>
    </Router>
  );
};

export default App;
