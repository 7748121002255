import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Course from "../components/mainCourse/Course";
import HeaderSvg from "../components/HeaderSvg";

const MainCourse = () => {
  return (
    <AppWrapper>
      <HeaderSvg />
      <Header />
      <Course />
      <Footer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div``;
export default MainCourse;
