import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import SubscriptionSection from "../components/mainSubscription/SubscriptionSection";
import Footer from "../components/Footer";
import HeaderSvg from "../components/HeaderSvg";

const MainSubscription = () => {
  return (
    <AppWrapper>
      <HeaderSvg />
      <Header />
      <SubscriptionSection />
      <Footer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div``;

export default MainSubscription;
