import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Lab from "../components/mainLab/Lab";
import HeaderSvg from "../components/HeaderSvg";

const MainLab = () => {
  return (
    <AppWrapper>
      <HeaderSvg />
      <Header />
      <Lab />
      <Footer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div``;

export default MainLab;
