import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Routs from "../components/mainRoute/Route";
import HeaderSvg from "../components/HeaderSvg";

const MainRoute = () => {
  return (
    <AppWrapper>
      <HeaderSvg />
      <Header />
      <Routs />
      <Footer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div``;
export default MainRoute;
