import React, { useState } from "react";
import styled from "styled-components";
import { IoMdMenu } from "react-icons/io";
import LanguageSelection from "./LanguageSelection";
import { Link } from "react-router-dom";

const LogoImage = styled.img`
  max-width: 60px;
  width: 100%;
  margin-left: 45px; /* Ajusté pour les grands écrans */
  @media (max-width: 991px) {
    margin-left: 30px;
  }
`;

const Logo = () => (
  <Link to="/">
    <LogoImage loading="lazy" src="./Assets/logo.png" alt="Company Logo" />
  </Link>
);

const NavWrapper = styled.nav`
  display: flex;
  gap: 10px; /* Réduit l'espace entre les éléments du menu pour les grands écrans */
  font-size: 14px;
  color: #212832;
  font-weight: 500;
  justify-content: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const NavItem = styled(Link)`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  cursor: pointer;
  color: #212832;
  padding: 10px 20px; /* Ajusté pour les grands écrans */
  text-decoration: none;
  display: block;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  &:hover {
    transform: scale(1.05);
    border: 1px solid #000;
  }
  @media (max-width: 991px) {
    padding: 8px 16px; /* Ajuste le padding pour les petits écrans */
    border-radius: 8px;
  }
`;

const navItems = [
  {
    title: "Parcours",
    route: "/route",
  },
  {
    title: "Cours",
    route: "/course",
  },
  {
    title: "Aloo",
    route: "/aloo",
  },
  {
    title: "Labs",
    route: "/lab",
  },
  {
    title: "Store",
    route: "/store",
  },
  {
    title: "Events",
    route: "/event",
  },
  {
    title: "Abonnements",
    route: "/subscription",
  },
];

const Navigation = () => (
  <NavWrapper>
    {navItems.map((item, index) => (
      <NavItem key={index} to={item.route}>
        {item.title}
      </NavItem>
    ))}
  </NavWrapper>
);

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Réduit l'espace entre les boutons d'action pour les grands écrans */
  @media (max-width: 991px) {
    display: none;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Réduit l'espace entre les boutons d'action pour les grands écrans */
`;

const Button = styled.button`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px; /* Réduit le padding des boutons pour les grands écrans */
  transition: color 0.3s;
  &:hover {
    color: #ff457d;
  }
  @media (max-width: 991px) {
    padding: 4px; /* Ajuste le padding pour les petits écrans */
  }
`;

const SignUpButton = styled(Button)`
  border: 1px solid #000;
  border-radius: 5px;
  padding: 6px 12px; /* Réduit le padding du bouton d'inscription pour les grands écrans */
  &:hover {
    background-color: #000;
    color: #fff;
  }
  @media (max-width: 991px) {
    padding: 4px 8px; /* Ajuste le padding pour les petits écrans */
  }
`;

const MobileMenuWrapper = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 10px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  @media (min-width: 992px) {
    display: none;
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px; /* Réduit l'espace dans le header pour les grands écrans */
  width: 95%;
  @media (max-width: 991px) {
    padding: 5px;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  color: #000;
  @media (max-width: 991px) {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
`;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <HeaderWrapper>
      <Logo />
      <HamburgerButton onClick={toggleMenu}>
        <IoMdMenu />
      </HamburgerButton>
      <Navigation />
      <ActionsWrapper>
        <ActionButtons>
          <SignUpButton
            onClick={() => {
              window.open("https://app.toyaloo.africa", "_blank");
            }}
          >
            S'inscrire
          </SignUpButton>
          <LanguageSelection />
        </ActionButtons>
      </ActionsWrapper>
      <MobileMenuWrapper isOpen={menuOpen}>
        {navItems.map((item, index) => (
          <NavItem key={index} to={item.route} onClick={closeMenu}>
            {item.title}
          </NavItem>
        ))}
        <SignUpButton
          onClick={() => {
            window.open("https://app.toyaloo.africa", "_blank");
          }}
        >
          S'inscrire
        </SignUpButton>
        <LanguageSelection />
      </MobileMenuWrapper>
    </HeaderWrapper>
  );
};

export default Header;
