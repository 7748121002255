import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Store from "../components/mainStore/Store";
import Footer from "../components/Footer";
import HeaderSvg from "../components/HeaderSvg";

const MainStore = () => {
  return (
    <AppWrapper>
      <HeaderSvg />
      <Header />
      <Store />
      <Footer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div``;

export default MainStore;
