import React from "react";
import styled from "styled-components";
import SubscriptionCard from "./SubscriptionCard";
import { TypeAnimation } from "react-type-animation";

const CardContainer = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const subscriptionData = [
  {
    type: "Standard",
    color: "#fdbb2d",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Quis ipsum suspendisse ultrices gravida.",
      "Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    ],
    imageSrc:
      "./Assets/abonnement1.png",
  },
  {
    type: "Family +",
    color: "#b61250",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Quis ipsum suspendisse ultrices gravida.",
      "Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    ],
    imageSrc:
    "./Assets/abonnement2.png",
  },
  {
    type: "Premium +",
    color: "#59B1E6",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Quis ipsum suspendisse ultrices gravida.",
      "Risus commodo viverra maecenas accumsan lacus vel facilisis.",
    ],
    imageSrc:
    "./Assets/abonnement3.png",
  },
];

function SubscriptionSection() {
  return (
    <StoreContainer>
      <Header>
        <AnimatedHeading
          sequence={[
            "Abonnements ",
            1000,
            "Subscriptions ",
            1000,
            "Usajili ",
            1000,
            "Kofuta ",
            1000,
          ]}
          speed={50}
          repeat={Infinity}
        />
      </Header>
      <MainContent>
        <CardContainer>
          {subscriptionData.map((subscription, index) => (
            <SubscriptionCard key={index} {...subscription} />
          ))}
        </CardContainer>
      </MainContent>
    </StoreContainer>
  );
}

const StoreContainer = styled.main`
  border-radius: 16px;
  /* background: color(display-p3 1 1 1); */
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px;
`;

const AnimatedHeading = styled(TypeAnimation)`
  font-family: Poppins, sans-serif;
  font-size: 18px;
  color: color(display-p3 0.0783 0.0806 0.0842);
  font-weight: 600;
  line-height: 111%;
  font-feature-settings: "clig" off, "liga" off;

  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
    white-space: normal;
  }
`;

const MainContent = styled.section`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  padding: 0 0 30px 5px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default SubscriptionSection;
