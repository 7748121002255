import React from "react";
import styled from "styled-components";
import {
  FaInstagram,
  FaFacebook,
  FaTiktok,
  FaLinkedin,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const NavigationColumn = ({ title, items }) => (
  <Column>
    <ColumnTitle>{title}</ColumnTitle>
    {items.map((item, index) => (
      <ColumnItem key={index}>{item}</ColumnItem>
    ))}
  </Column>
);

const Column = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: var(--TEXT-CLR, #5e6282);
  font-weight: 500;
  line-height: 124.5%;
  margin: auto 0;
`;

const ColumnTitle = styled.h3`
  color: var(--black, #080809);
  font: 700 21px Poppins, sans-serif;
`;

const ColumnItem = styled.a`
  font-family: Poppins, sans-serif;
  margin-top: 15px;
  cursor: pointer;
`;

const Logo = () => (
  <LogoWrapper>
    <LogoImage
      loading="lazy"
      src="./Assets/toyaloo_1.png"
      alt="Toyaloo Logo"
    />
    <Tagline>Nous réinventons l'apprentissage.</Tagline>
  </LogoWrapper>
);

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: var(--TEXT-CLR, #5e6282);
  font-weight: 500;
  line-height: 124.5%;
`;

const LogoImage = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 163px;
  max-width: 100%;
`;

const Tagline = styled.p`
  font-family: Poppins, sans-serif;
  font-size: 18px;
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const DownloadSection = () => (
  <DownloadWrapper>
    <SocialWrapper>
      <FaInstagram size={30} onClick={alert} />
      <FaFacebook size={30} onClick={alert} />
      <BsTwitterX size={30} onClick={alert} />
      <FaTiktok size={30} onClick={alert} />
      <FaLinkedin size={30} onClick={alert} />
    </SocialWrapper>
    <DownloadText>
      Télécharger l'application <br /> mobile
    </DownloadText>
    <StoreButtons>
      <StoreButton
        loading="lazy"
        src="./Assets/app_store.svg"
        alt="App Store"
      />
      <StoreButton
        loading="lazy"
        src="./Assets/play_store.svg"
        alt="Google Play"
      />
    </StoreButtons>
  </DownloadWrapper>
);

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 57px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const DownloadText = styled.p`
  color: var(--TEXT-CLR, #5e6282);
  text-align: center;
  letter-spacing: 0.1px;
  margin-top: 29px;
  font: 500 20px/25px Poppins, sans-serif;
`;

const StoreButtons = styled.div`
  display: flex;
  gap: 7px;
  margin: 16px 19px 0;
  justify-content: center;
  @media (max-width: 991px) {
    margin: 0 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const StoreButton = styled.img`
  aspect-ratio: 3.13;
  object-fit: auto;
  object-position: center;
  width: 107px;
  max-width: 100%;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigationData = [
    {
      title: "Pages",
      items: ["A Propos", "Connexion", "Parcours"],
    },
    {
      title: "Contact",
      items: ["Aide/FAQ", "Blog", "Partenaires"],
    },
    {
      title: "A propos",
      items: ["Abonnement", "Toyaloo team", "En savoir plus"],
    },
  ];

  return (
    <FooterWrapper>
      <FooterContent>
        <Logo />
        {navigationData.map((column, index) => (
          <NavigationColumn
            key={index}
            title={column.title}
            items={column.items}
          />
        ))}
        <DownloadSection />
      </FooterContent>
      <Copyright>toyaloo.africa &copy; {currentYear} </Copyright>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  text-align: center;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: var(--TEXT-CLR, #5e6282);
  align-self: center;
  margin-top: 39px;
  font: 500 14px/124.5% Poppins, sans-serif;
`;

export default Footer;
