import React from "react";
import styled from "styled-components";
import { IoIosPlay } from "react-icons/io";
import { TypeAnimation } from "react-type-animation";

function Header() {
  return (
    <HeaderWrapper>
      <Title>Apprentissage interactif</Title>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: #af00f1;
  text-transform: uppercase;
  font: 20px Poppins, sans-serif;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 18px;
    text-align: center;
  }
`;

const commonHeadingStyles = `
  color: var(--1st, #181e4b);
  letter-spacing: -2.4px;
  font-family: Volkhov, sans-serif;
`;

const AnimatedHeading = styled(TypeAnimation)`
  ${commonHeadingStyles}
  font-size: 60px;
  line-height: 76px;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 56px;
    text-align: center;
  }
`;

const Heading = styled.h2`
  ${commonHeadingStyles}
  font-size: 60px;
  line-height: 76px;
  margin-top: 10px;
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 56px;
    text-align: center;
  }
`;

function MainContent() {
  return (
    <Main>
      <AnimatedHeading
        sequence={[
          "Nous réinventons",
          1000,
          "Nous boostons",
          1000,
          "Nous optimisons",
          1000,
          "Nous ludifions",
          1000,
        ]}
        speed={50}
        repeat={Infinity}
      />
      <Heading>l'apprentissage !</Heading>
      <Description>
        Revoyez vos leçons et exercez-vous selon la matière <br /> de votre
        choix tout en vous amusant.
      </Description>
    </Main>
  );
}

const Main = styled.main`
  width: 100%;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: #5e6282;
  margin-top: 10px;
  font: 500 16px/30px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    text-align: center;
  }
`;

// Définir l'animation de survol pour le Button
const Button = styled.button`
  font-family: Noto Sans JP, sans-serif;
  border-radius: 10px;
  background: #f1a501;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px 28px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s; /* Ajouter des transitions pour une animation fluide */

  &:hover {
    background: #d18a02; /* Changer la couleur de fond au survol */
    transform: scale(1.05); /* Agrandir légèrement le bouton au survol */
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

// Définir l'animation de survol pour le PlayWrapper
const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #af00f1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s, transform 0.3s; /* Ajouter des transitions pour une animation fluide */
`;

const Icon = styled(IoIosPlay)`
  color: #fff;
  font-size: 20px;
`;

const PlayWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s; /* Ajouter une transition pour une animation fluide */

  &:hover {
    transform: scale(1.05); /* Agrandir légèrement l'ensemble au survol */
  }

  &:hover ${Circle} {
    background-color: #9c00d4; /* Changer la couleur de fond du cercle au survol */
    transform: scale(1.1); /* Agrandir légèrement le cercle au survol */
  }

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Text = styled.p`
  font-family: Noto Sans JP, sans-serif;
  color: #5e6282;
  margin-left: 8px;
`;

function Footer() {
  return (
    <FooterWrapper>
      <Button
        onClick={() => {
          window.open("https://app.toyaloo.africa", "_blank");
        }}
      >
        Commencer
      </Button>
      <PlayWrapper>
        <Circle>
          <Icon />
        </Circle>
        <Text>Jouer à la Démo</Text>
      </PlayWrapper>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

function InteractiveLearning() {
  return (
    <Container>
      <Header />
      <MainContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export default InteractiveLearning;
