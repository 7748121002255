import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importation des fichiers de traduction
import homeEN from "./locales/en/home.json";
import homeFR from "./locales/fr/home.json";

const savedLanguage = localStorage.getItem("language") || "en";

// La configuration de i18next
i18n
  .use(initReactI18next) // Intégration de i18next avec React
  .init({
    resources: {
      en: {
        homeEN: homeEN,
      },
      fr: {
        homeFR: homeFR,
      },
    },
    lng: savedLanguage, // Langue par défaut
    fallbackLng: "en", // Langue de secours
    interpolation: {
      escapeValue: false, // React s'occupe déjà de l'échappement des valeurs
    },
  });

export default i18n;
