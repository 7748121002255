import React from "react";
import styled from "styled-components";
import { IMAGE_URL } from "../../api/Api";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 1000px; /* Ajustez la largeur pour contenir les cartes */
`;

const Card = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 30%; /* Chaque carte occupe 30% de la largeur du conteneur */
  margin: 10px 0; /* Ajustez l'espacement vertical entre les lignes */
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%; /* En mode responsive, chaque carte occupe 100% de la largeur du conteneur */
  }
`;

const CardImage = styled.img`
  display: flex;
  flex: 1;
  width: 100%;
  object-fit: cover;
`;

const CardFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  height: 15%;
  gap: 10px;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  color: var(--TEXT-CLR, #5e6282);
`;

const CardButton = styled.button`
  font-family: Noto Sans JP, sans-serif;
  border-radius: 10px;
  background: #ff457d;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px 28px;
  cursor: pointer;
  transition: transform 0.3s; /* Ajouter une transition pour une animation fluide */

  &:hover {
    transform: scale(1.05); /* Agrandir légèrement le bouton au survol */
  }

  @media (max-width: 991px) {
    width: 50%;
    margin-bottom: 10px;
  }
`;

const CardDescription = styled.p`
  color: var(--TEXT-CLR, #5e6282);
  font: 500 16px Montserrat, sans-serif;
  margin: 10px;
`;

const openRoute = (id) => {
  alert(`the item done is ${id}`);
};

const RouteItem = ({ id, src, name, content_json }) => (
  <Card>
    <CardImage src={IMAGE_URL + content_json.images[0]} alt={name} />
    <CardFooter>
      <CardTitle>{name}</CardTitle>
      <CardDescription>{content_json.description}</CardDescription>
      <CardButton onClick={() => openRoute(id)}>Commencer</CardButton>
    </CardFooter>
  </Card>
);

const CardList = ({ items }) => (
  <CardContainer>
    {items.map((item) => (
      <RouteItem key={item.id} {...item} />
    ))}
  </CardContainer>
);

export default CardList;
