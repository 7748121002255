import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import LeftForm from "../svg/left_form.svg";
import HomeForm from "../svg/home_form.svg";
import MainForm from "../svg/main_form.svg";

const Wrapper = styled.div``;

const LeftStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: auto;
  z-index: -1;
`;

const RightStyled = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: auto;
  z-index: -1;
  filter: ${({ colorFilter }) => colorFilter || "none"};
`;

const HeaderSvg = () => {
  const location = useLocation();
  const path = location.pathname;

  let RightComponent = HomeForm;
  let colorFilter = "none";

  if (path !== "/") {
    RightComponent = MainForm;

    // Changer les couleurs en fonction du chemin
    switch (path) {
      case "/route":
        colorFilter = "invert(0.5) sepia(1) saturate(2)";
        break;
      case "/course":
        colorFilter = "contrast(1.5) hue-rotate(-220deg) saturate(3.5)";
        break;
      case "/aloo":
        colorFilter = "brightness(1.1) saturate(1.5) hue-rotate(-40deg) sepia(0.5)";
        break;
      case "/lab":
        colorFilter = "hue-rotate(240deg) contrast(1.2)";
        break;
      case "/store":
        colorFilter = "brightness(0.9) saturate(1.4) hue-rotate(280deg) sepia(0.3)";
        break;
      case "/event":
        colorFilter = "sepia(1) brightness(1.1)";
        break;
      case "/subscription":
        colorFilter = "brightness(0.9) saturate(1.3) hue-rotate(-20deg) sepia(0.4)";
        break;
      default:
        colorFilter = "none";
    }
  }

  return (
    <Wrapper>
      <LeftStyled src={LeftForm} alt="SVG Left" />
      <RightStyled
        src={RightComponent}
        alt="SVG Right"
        colorFilter={colorFilter}
      />
    </Wrapper>
  );
};

export default HeaderSvg;
