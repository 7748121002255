import React from "react";
import styled, { keyframes } from "styled-components";
import InteractiveLearning from "./InteractiveLearning";

// Définir l'animation de rebondissement
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

// Appliquer l'animation au HeroImage
const HeroImage = styled.img`
  max-width: 600px;
  width: 100%;
  margin-top: -10%;
  animation: ${bounce} 3s infinite; // 3s pour une animation lente
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 48px;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;

const Hero = () => (
  <HeroSection>
    <InteractiveLearning />
    <HeroImage
      src="./Assets/fillette_3.png"
      alt="Interactive Learning"
      loading="lazy"
    />
  </HeroSection>
);

export default Hero;
