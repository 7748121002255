import React from "react";
import styled from "styled-components";
import LabFeature from "./LabFeature";

const LabsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Ajouté pour un padding réactif */
  }
`;

const LabsContent = styled.div`
  max-width: 50%;
  margin: 0 20px; /* Ajouté pour la marge */
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 0 20px 20px; /* Ajouté pour la marge et la marge inférieure */
  }
`;

const LabsTitle = styled.h2`
  color: var(--3rd, #14183e);
  font: 700 40px Volkhov, sans-serif;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    font-size: 30px;
    text-align: center;
  }
`;

const LabsImage = styled.img`
  max-width: 35%; /* Réduit de 45% à 35% */
  margin: 0 20px; /* Ajouté pour la marge */
  height: 100%;
  border-radius: 20px;
  @media (max-width: 991px) {
    max-width: 80%; /* Réduit de 100% à 80% */
    margin: 0 20px; /* Ajouté pour la marge */
    height: 300px;
  }
`;

const labFeatures = [
  {
    title: "Apprentissage Lucide",
    description:
      "Apprentissage ludique en offrant une expérience d'apprentissage immersive et stimulante engageant les enfants à plusieurs niveaux.",
    iconSrc:
      "./Assets/group_7.png",
  },
  {
    title: "Pratique",
    description:
      "En manipulant des objets et en expérimentant différentes activités, les enfants acquièrent une compréhension plus profonde des concepts qu'ils apprennent.",
    iconSrc:
      "./Assets/group_12.png",
  },
  {
    title: "Intuitif",
    description:
      "Cela stimule les sens et aide les apprenants à développer une compréhension spatiale et une mémoire musculaire des concepts.",
    iconSrc:
      "./Assets/group_11.png",
  },
];

const LabsSection = () => (
  <LabsWrapper>
    <LabsContent>
      <LabsTitle>
        Labs
        <br />
        Des kits d'apprentissage
        <br />
        Simple et fluide.
      </LabsTitle>
      {labFeatures.map((feature, index) => (
        <LabFeature key={index} {...feature} />
      ))}
    </LabsContent>
    <LabsImage src="./Assets/labs.jpg" alt="Labs Kit" loading="lazy" />
  </LabsWrapper>
);

export default LabsSection;
