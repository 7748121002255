import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Hero from "../components/mainHome/Hero";
import CourseCategories from "../components/mainHome/CourseCategories";
import LearningPaths from "../components/mainHome/LearningPaths";
import LabsSection from "../components/mainHome/LabsSection";
import Partners from "../components/mainHome/Partners";
import Footer from "../components/Footer";
import HeaderSvg from "../components/HeaderSvg";

const MainWrapper = styled.div`
  font-family: "Poppins", sans-serif;
`;

const MainPage = () => (
  <MainWrapper>
    <HeaderSvg />
    <Header />
    <Hero />
    <CourseCategories />
    <LearningPaths />
    <LabsSection />
    <Partners />
    <Footer />
  </MainWrapper>
);

export default MainPage;
