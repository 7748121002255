import React from "react";
import styled, { keyframes } from "styled-components";

const PartnersSection = styled.section`
  text-align: center;
  padding: 80px 0;
  overflow: hidden; /* Ensure the overflow is hidden to manage scrolling content */
`;

const SectionTitle = styled.h2`
  color: var(--3rd, #14183e);
  font: 700 50px Volkhov, sans-serif;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const PartnerLogos = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  animation: ${scroll} 20s linear infinite;
  @media (max-width: 991px) {
    gap: 20px;
  }
`;

const PartnerLogo = styled.img`
  max-width: 200px;
  height: auto;
  @media (max-width: 991px) {
    max-width: 100px;
  }
`;

const Partners = () => (
  <PartnersSection>
    <SectionTitle>Nos partenaires</SectionTitle>
    <PartnerLogos>
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/70559b954f7d167e9a3b3a122f57cbc2286eb400d3e2c7e183355fe3c1e76527?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 1"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/76b3e5fdbdabe13bf02e1fe125eb24b4925cbe59c640cb0984b83c733039b05b?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 2"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d05d60237b72f3e4664952471a2c26cbcbd3cd5265e3e68e4caab6fcabfe2a1?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 3"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/94dca7acb46aff44afbe36c5b23a266bdab9759905928fbcd584de063fcbf8de?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 4"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/70559b954f7d167e9a3b3a122f57cbc2286eb400d3e2c7e183355fe3c1e76527?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 1"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/76b3e5fdbdabe13bf02e1fe125eb24b4925cbe59c640cb0984b83c733039b05b?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 2"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d05d60237b72f3e4664952471a2c26cbcbd3cd5265e3e68e4caab6fcabfe2a1?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 3"
        loading="lazy"
      />
      <PartnerLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/94dca7acb46aff44afbe36c5b23a266bdab9759905928fbcd584de063fcbf8de?apiKey=2be8189d03904e6583ac9ca6f35b4f3d&"
        alt="Partner 4"
        loading="lazy"
      />
    </PartnerLogos>
  </PartnersSection>
);

export default Partners;
