import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CourseCard from "./CourseCard";
import axios from "axios";
import { BASE_URL, headers } from "../../api/Api";

const CategorySection = styled.section`
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: var(--3rd, #14183e);
  font: 700 40px Volkhov, sans-serif;
  @media (max-width: 991px) {
    font-size: 30px;
    text-align: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const CourseCategories = () => {
  const [donnees, setDonnees] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}course/four`, headers)
      .then((response) => {
        if (response.data.state === 1) {
          setDonnees(response.data.data);
          console.log(JSON.stringify(response));
        } else {
          setDonnees([]);
        }
      })
      .catch((error) => {
        console.error("Une erreur est survenue :", error);
      });
  }, []); // Passer un tableau vide comme dépendance pour exécuter l'effet une seule fois au chargement du composant

  return (
    <CategorySection>
      <SectionTitle>Cours en Accès Libre</SectionTitle>
      <CardContainer>
        {donnees.map((donnee, index) => (
          <CourseCard key={index} {...donnee} />
        ))}
      </CardContainer>
    </CategorySection>
  );
};

export default CourseCategories;
