import React from "react";
import styled from "styled-components";

const FeatureWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const FeatureIcon = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 5px;
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h3`
  color: var(--TEXT-CLR, #5e6282);
  font: 700 16px Poppins, sans-serif;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  color: var(--TEXT-CLR, #5e6282);
  font: 400 16px/20px Poppins, sans-serif;
`;

const LabFeature = ({ title, description, iconSrc }) => (
  <FeatureWrapper>
    <FeatureIcon src={iconSrc} alt={title} loading="lazy" />
    <FeatureContent>
      <FeatureTitle>{title}</FeatureTitle>
      <FeatureDescription>{description}</FeatureDescription>
    </FeatureContent>
  </FeatureWrapper>
);

export default LabFeature;
