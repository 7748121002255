import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import CardList from './Card';
import axios from 'axios';
import { BASE_URL, headers } from '../../api/Api';

const ProductGrid = () => {
  const [donnees, setDonnees] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}store/lab`, headers)
      .then(response => {
        if (response.data.state === 1) {
          setDonnees(response.data.data);
          console.log(JSON.stringify(response));
        } else {
          setDonnees([]);
        }
      })
      .catch(error => {
        console.error('Une erreur est survenue :', error);
      });
  }, []); // Passer un tableau vide comme dépendance pour exécuter l'effet une seule fois au chargement du composant

  return (
    <Container>
      <CardList items={donnees} /> {/* Utilisation du composant CardList pour afficher les cartes */}
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
`;

export default ProductGrid;
