export const BASE_URL = "https://api.toyaloo.africa/v1/";

export const IMAGE_URL = "https://api.toyaloo.africa/";

// export const BASE_URL = "http://172.20.10.9:5000/v1/"

// export const IMAGE_URL = "http://172.20.10.9:5000/"

export const headers = {
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    "Content-Type": "multipart/form-data; ",
  },
};
