import React from "react";
import styled from "styled-components";

const Card = styled.article`
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: 20px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 991px) {
    margin-top: 25px;
  }
`;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const CardContent = styled.div`
  background-color: ${(props) => props.color};
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 300px; /* Ajustez cette valeur si nécessaire */
  flex-grow: 1;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const CardTitle = styled.h2`
  font: 800 32px/124.5% Poppins, sans-serif;
  margin-bottom: 16px;
  @media (max-width: 991px) {
    margin: 0 6px;
  }
`;

const CardDescription = styled.ul`
  font: 500 16px/20px Poppins, sans-serif;
  padding-left: 20px; /* Ajouté pour les puces */
  flex-grow: 1;
  @media (max-width: 991px) {
    margin: 0 6px;
  }
`;

const CardDescriptionItem = styled.li`
  margin-bottom: 10px;
`;

const Button = styled.button`
  font-family: Noto Sans JP, sans-serif;
  border-radius: 10px;
  background: #ff457d;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  color: white;
  font-weight: bold;
  border: none;
  padding: 12px 32px;
  cursor: pointer;
  aspect-ratio: 3.45;
  object-fit: auto;
  object-position: center;
  width: 133px;
  align-self: center;
  margin-top: 20px;
  max-width: 100%;
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    background: #ff6f91;
    transform: scale(1.05);
  }

  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;

const SubscriptionCard = ({ type, color, description, imageSrc }) => (
  <Card>
    <CardImage src={imageSrc} alt={`${type} subscription`} />
    <CardContent color={color}>
      <CardTitle>{type}</CardTitle>
      <CardDescription>
        {description.map((item, index) => (
          <CardDescriptionItem key={index}>{item}</CardDescriptionItem>
        ))}
      </CardDescription>
      <Button>Souscrire</Button>
    </CardContent>
  </Card>
);

export default SubscriptionCard;
