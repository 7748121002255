import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: auto 0;
  padding: 6px 0;
  cursor: pointer;
`;

const Language = styled.span`
  font-family: Noto Sans JP, sans-serif;
  text-transform: uppercase;
`;

const PopupContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 80px; /* Réduisez cette valeur selon vos besoins */
`;

const LanguageOption = styled.div`
  padding: 5px 0;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const LanguageSelection = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setCurrentLanguage(lng);
  };

  useEffect(() => {
    // Mettre à jour l'état si la langue change en dehors de cette composant
    const handleLanguageChanged = (lng) => {
      setCurrentLanguage(lng);
    };

    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [i18n]);

  return (
    <Popup
      trigger={
        <SelectorWrapper>
          <Language>{currentLanguage}</Language>
          <IoIosArrowDown />
        </SelectorWrapper>
      }
      position="bottom center"
      closeOnDocumentClick
      contentStyle={{
        width: "auto",
        padding: "0",
      }} /* Style inline pour ajuster la largeur du popup */
    >
      {(close) => (
        <PopupContent>
          <LanguageOption onClick={() => changeLanguage("fr")}>
            Français
          </LanguageOption>
          <LanguageOption onClick={() => changeLanguage("en")}>
            English
          </LanguageOption>
          {/* Ajoutez d'autres langues ici */}
        </PopupContent>
      )}
    </Popup>
  );
};

export default LanguageSelection;
